import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  extract_and_set_params(url) {
    const params = {};
    const queryString = url.split('?')[1];
    if (queryString) {
      queryString.split('&').forEach(param => {
        const parts = param.split('=');
        params[parts[0]] = parts[1];
      });
    }
    localStorage.setItem('params', JSON.stringify(params));
  }
}
